// @flow
import { useMemo, useEffect, useRef } from 'react';
import { GET_BASE_VIEWER_DATA } from 'modules/Apollo/queries';
import useStudyIds from 'hooks/useStudyIds';
import { useWorklistIds } from 'hooks/useWorklistIds';
import { useRegisterStudyColors } from 'hooks/useStudyColor';
import type { Study, StudyGroup } from 'domains/viewer/ViewportsConfigurations/types';
import type { GetBaseViewerDataQuery, GetBaseViewerDataQueryVariables } from 'generated/graphql';
import { seriesToSlimSeries, stackToSlimStack } from '../../ViewportsConfigurations/manipulators';
import type { ApolloQueryResult } from '@apollo/client';
import typeof { NetworkStatus } from '@apollo/client';
import { emitPrecacheStatus } from 'modules/Apollo/emitPrecacheStatus';
import { usePrecacheQuery } from 'modules/Apollo/usePrecacheQuery';
import { useIsViewerMetadataPrecacheEnabled } from './useIsViewerMetadataPrecacheEnabled';
import { deletePrecacheQuery } from 'modules/Apollo/precacheOperation';
import { logger } from 'modules/logger';
import { FF, useFeatureFlagEnabled } from 'modules/feature-flags/suspense';

export const useStudyColorTracker = ({
  loadedStudies,
  pendingStudies,
}: {
  loadedStudies: string[],
  pendingStudies: string[],
}): void => {
  const registerStudyColors = useRegisterStudyColors();

  useEffect(() => {
    registerStudyColors(loadedStudies, pendingStudies);
  }, [loadedStudies, pendingStudies, registerStudyColors]);
};

type BaseViewerDataHook = {
  studies: $ReadOnlyArray<Study>,
  mainStudiesIds: $ReadOnlyArray<string>,
  loading: boolean,
  notFound: boolean,
  smids?: Array<string>,
  refetch: () => Promise<ApolloQueryResult<GetBaseViewerDataQuery>>,
  hangingProtocol?: GetBaseViewerDataQuery['matchHangingProtocolByWorkListsAndStudies'],
  networkStatus: $Values<NetworkStatus>,
  presentationStates: GetBaseViewerDataQuery['presentationStates'],
};

export const useBaseViewerData = (
  { shouldEmitPrecacheStatus }: { shouldEmitPrecacheStatus: boolean } = {
    shouldEmitPrecacheStatus: false,
  }
): BaseViewerDataHook => {
  const studyIds = useStudyIds();
  const worklistIds = useWorklistIds();
  const emittedPrecacheStatus = useRef(false);
  const hasWorklistIds = worklistIds.length > 0;
  const queryVariables = useMemo(
    () => ({
      worklistIds,
      studyIds,
      hasWorklistIds,
      hasStudyIds: studyIds.length > 0,
    }),
    [studyIds, worklistIds, hasWorklistIds]
  );

  const shouldRefetch = useFeatureFlagEnabled(FF.VIEWER_DATA_REFETCH);
  const precacheEnabled = useIsViewerMetadataPrecacheEnabled();

  const { loading, data, previousData, refetch, error, networkStatus } = usePrecacheQuery<
    GetBaseViewerDataQuery,
    GetBaseViewerDataQueryVariables,
  >(
    GET_BASE_VIEWER_DATA,
    {
      variables: queryVariables,
      skip: !hasWorklistIds,
      subscribeToNetworkChanges: true,
    },
    {
      shouldRefetch,
      precacheEnabled,
    }
  );

  // We use the previous data to avoid flickering when the query is loading
  let latestPayload = data;
  if (latestPayload == null && previousData?.workListItems != null) {
    const hasMatches = worklistIds.some((worklistId) => {
      return previousData?.workListItems?.items.some((worklist) => worklist.smid === worklistId);
    });

    if (hasMatches) {
      latestPayload = previousData;
    } else {
      emittedPrecacheStatus.current = false;
    }
  }

  useEffect(() => {
    if (!shouldEmitPrecacheStatus || emittedPrecacheStatus.current) return;
    emitPrecacheStatus(GET_BASE_VIEWER_DATA, queryVariables);
    emittedPrecacheStatus.current = true;
  }, [queryVariables, shouldEmitPrecacheStatus]);

  const mainStudiesIds = useMemo(
    () =>
      latestPayload?.workListItems?.items
        .flatMap((worklist) => worklist.studies)
        .map((study) => study.smid) ?? [],
    [latestPayload]
  );

  const studies = useMemo(() => {
    const mainStudies =
      latestPayload?.workListItems?.items.flatMap((worklist) => worklist.studies) ?? [];
    const comparativeStudies = latestPayload?.studiesBySmid ?? [];
    return [...mainStudies, ...comparativeStudies].filter(Boolean);
  }, [latestPayload]);

  // This is a failsafe mechanism to delete the cache if the query returns no studies
  // If no studies are returned it's VERY likely something in our cache is wrong and
  // it does no harm to delete it for this query since it's theoretically empty anyway
  useEffect(() => {
    if (!loading && studies.length === 0) {
      logger.info('Deleting precache query GET_BASE_VIEWER_DATA with variables:', queryVariables);
      deletePrecacheQuery({ query: GET_BASE_VIEWER_DATA, variables: queryVariables });
    }
  }, [loading, queryVariables, studies.length]);

  const unableToFindStudyError =
    error?.graphQLErrors?.some((err) => err.extensions.code === 404) ?? false;

  const notFound = !loading && (unableToFindStudyError || studies.length === 0);

  const smids = useMemo(() => studies.map((study) => study.smid), [studies]);

  const hangingProtocol = latestPayload?.matchHangingProtocolByWorkListsAndStudies;
  const presentationStates = useMemo(
    () => latestPayload?.presentationStates ?? [],
    [latestPayload]
  );

  return {
    smids,
    studies,
    mainStudiesIds,
    loading,
    notFound,
    refetch,
    hangingProtocol,
    networkStatus,
    presentationStates,
  };
};

export type StudiesHookResult = {
  groups: Array<Array<string>>,
  groupedStudies: Array<StudyGroup>,
  ...BaseViewerDataHook,
};
export const useStudies = (): StudiesHookResult => {
  const { smids = [], studies, mainStudiesIds, ...studiesHook } = useBaseViewerData();
  const groups: Array<Array<string>> = useMemo(() => {
    const currentCaseStudies = studies.filter((study) => mainStudiesIds.includes(study.smid));

    const primaryStudySmids = currentCaseStudies.map((study) => study.smid) ?? [];
    return [
      primaryStudySmids,
      ...smids.filter((smid) => !primaryStudySmids.includes(smid)).map((smid) => [smid]),
    ].filter((group) => group.length > 0);
  }, [mainStudiesIds, smids, studies]);

  const groupedStudies: Array<StudyGroup> = useMemo(
    () =>
      groups.map((group) =>
        group.reduce<StudyGroup>((acc, studySmid) => {
          const study = studies.find((study) => study.smid === studySmid);
          if (study == null) return acc;
          return [
            ...acc,
            {
              smid: study.smid,
              description: study.description,
              seriesList: study.seriesList.map((series) => seriesToSlimSeries(series)),
              stackedFrames: study.stackedFrames.map((stack) => stackToSlimStack(stack)),
            },
          ];
        }, [])
      ),
    [groups, studies]
  );

  return {
    groups,
    groupedStudies,
    studies,
    mainStudiesIds,
    ...studiesHook,
  };
};
