// @flow
import type { Preference } from '.';

const keyboardShortcuts = {
  reporterPrevious: {
    id: 'REPORTER_PREVIOUS',
    description: 'Focus Previous',
    iconName: 'skip_previous',
    category: 'REPORTER',
  },
  reporterNext: {
    id: 'REPORTER_NEXT',
    description: 'Focus Next',
    iconName: 'skip_next',
    category: 'REPORTER',
  },
  reporterToggleDictation: {
    id: 'REPORTER_TOGGLE_DICTATION',
    description: 'Toggle Dictation',
    iconName: 'fiber_manual_record',
    category: 'REPORTER',
  },
  reporterSignReport: {
    id: 'REPORTER_SUBMIT_REPORT',
    description: 'Sign Report',
    iconName: 'submitReport',
    category: 'REPORTER',
  },
  reporterGenerateImpression: {
    id: 'REPORTER_GENERATE_IMPRESSION',
    description: 'Generate Impression',
    iconName: 'generateImpression',
    category: 'REPORTER',
  },
  reporterMedCheck: {
    id: 'REPORTER_MED_CHECK',
    description: 'Med Check',
    iconName: 'medCheck',
    category: 'REPORTER',
  },
  reporterDiscardReport: {
    id: 'REPORTER_DISCARD_REPORT',
    description: 'Discard Report',
    iconName: 'delete',
    category: 'REPORTER',
  },
};

export const getKeyboardShortcutById = (id: string, interactionId: ?string): ?Preference =>
  // $FlowFixMe[incompatible-use]
  // $FlowFixMe[incompatible-type]
  Object.values(keyboardShortcuts).find((ks) => ks.id === id);

export default keyboardShortcuts;
